import React, { useEffect } from "react";
import Sidebar from "../includes/Sidebar";
import Footer from "../includes/Footer";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { useState } from "react";
import AdminListService from "../../../services/admin-list.service";

import MainHeading from "../includes/MainHeading";
import Skeleton from "react-loading-skeleton";
import Swal from "sweetalert2";
import Pagination from "react-js-pagination";
import { useFormik } from "formik";
import { quizSearch } from "../../../schema";
import moment from "moment";
import { toast } from "react-toastify";
import formatThousands from "format-thousands";

export default function ViewSurvey() {
  const [tableLoader, settableLoader] = useState(false);

  const [tableData, settableData] = useState([]);
  const [limit, setLimit] = useState("10");

  const [currentPage, setCurrentPage] = useState(1);
  const [currentDateTime, setCurrentDateTime] = useState("");

  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("0");

  const state = useSelector((state) => state.stateVals);
  const { adminAccessToken, admin_id } = state;
  const navigate = useNavigate();

  const surveyListSubmitted = async (page, limit, values) => {
    settableLoader(true);
    try {
      const responce = await AdminListService.getSubmittedSurvey(
        page,
        limit,
        values,
        "active"
      );

      const res = responce?.data?.response;

      const results = [];
      res.map((value) => {
        return results.push({
          email: value.email,
          created_at: value.created_at,
          ans1: value.ans1,
          ans2: value.ans2,
          ans3: value.ans3,
          ans4: value.ans4,
          ans5: value.ans5,
          ans6: value.ans6,
          ans7: value.ans7,
          ans8: value.ans8,
          ans9: value.ans9,
          ans10: value.ans10,

          id: value.id,
        });
      });

      settableData([...results]);
      setTotalPages(responce?.data?.total_pages);
      setTotalResults(responce?.data?.records);
      setCurrentPage(responce?.data?.current_page);
      setLimit(responce?.data?.per_page);

      settableLoader(false);
    } catch (err) {
      console.log(err);
      settableLoader(false);
      settableData([]);
      setTotalPages("0");
      setTotalResults("0");
    }
  };

  useEffect(() => {
    if (adminAccessToken) {
      surveyListSubmitted("1", limit, []);

      const updateTimestamp = () => {
        const timestampInSeconds = Math.floor(Date.now() / 1000);
        setCurrentDateTime(timestampInSeconds);
      };

      const intervalId = setInterval(updateTimestamp, 1000);

      return () => clearInterval(intervalId);
    } else {
      navigate("/admin/login", { replace: true });
    }

    // eslint-disable-next-line
  }, []);

  const archiveAllSurveys = async () => {
    Swal.fire({
      title: "Are You Sure?",
      text: "Do You Want To Archive All Surveys",
      icon: "question",
      iconColor: "#e04f5d",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      confirmButtonText: "Yes",
      cancelButtonText: "Close",
      customClass: {
        container: "del-popup", // Apply your custom class to the container
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await AdminListService.archiveAllOrders(admin_id);

          surveyListSubmitted(currentPage, limit, values);

          Swal.fire({
            icon: "success",
            title: "Archived Successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
        } catch (err) {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            iconColor: "#e04f5d",
            confirmButtonColor: "#e72e2e",
            text: "Something went wrong!",
          });
        }
      }
    });
  };

  const archiveSurvey = async (order_id) => {
    settableLoader(true);
    try {
      const response = await AdminListService.surveyArchive(admin_id, order_id);

      toast.success(response?.data?.response, {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      settableLoader(false);
      setTimeout(() => {
        surveyListSubmitted(currentPage, limit, values);
      }, 1000);
    } catch (err) {
      console.log(err);
      settableLoader(false);

      if (
        err?.response?.data?.error &&
        typeof err?.response?.data?.error === "string"
      ) {
        toast.error(err?.response?.data?.error, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error("Something went wrong, try again", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,

      initialValues: {
        fieldtype: "",
        searchval: "",
      },
      validationSchema: quizSearch,
      onSubmit: (values) => {
        surveyListSubmitted("1", limit, values);
      },
    });

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <Sidebar />
          <MainHeading heading="Manage Users Survey" />

          <div className="mt-4 row">
            <div className="col-md-12 col-12">
              <div className="card dashboard-cards">
                <div className="bg-white  py-3 card-header">
                  <div className="row">
                    <div className="col-lg-8">
                      <h4 className="mb-0">
                        Total Surveys:{" "}
                        <span className="text-primary-color fw-bold">
                          {formatThousands(totalResults, {
                            separator: ",",
                          })}
                        </span>
                      </h4>
                    </div>
                    <div className="col-lg-4 text-end">
                      <button
                        className="btn btn-success btn-sm btn-icon-text"
                        onClick={archiveAllSurveys}
                      >
                        Archive All Survey <i className="bi bi-archive"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <form className="row" onSubmit={handleSubmit} noValidate>
                    <div className="col-6 ">
                      <Link
                        to="/admin/archive_survey"
                        className="btn btn-primary btn-sm btn-icon-text me-2"
                      >
                        Show Archive Survey <i className="bi bi bi-archive"></i>
                      </Link>
                    </div>
                    <div className="col-6 text-end">
                      <a
                        className="btn btn-primary btn-sm btn-icon-text "
                        href={`${process.env.REACT_APP_API_Link}export-surveys/active?time=${currentDateTime}`}
                      >
                        To Export As Excel Click here{" "}
                        <i className="bi bi-filetype-exe"></i>
                      </a>
                    </div>
                    <div className="col-12">
                      <hr />
                    </div>
                    <div className="col-lg-3">
                      <div className="input-field-box mb-lg-0">
                        <label>Show Column</label>
                        <select
                          className={`form-select ${
                            errors.fieldtype && touched.fieldtype
                              ? "is-danger"
                              : ""
                          }`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="fieldtype"
                          value={values.fieldtype || ""}
                          required
                        >
                          <option value="">Select Column</option>
                          <option value="email">Email </option>
                          <option value="ans1"> Answer1 </option>
                          <option value="ans2"> Answer2 </option>
                          <option value="ans3"> Answer3 </option>
                          <option value="ans4"> Answer4 </option>
                          <option value="ans5"> Answer5 </option>
                          <option value="ans6"> Answer6 </option>
                          <option value="ans7"> Answer7 </option>
                          <option value="ans8"> Answer8 </option>
                          <option value="ans9"> Answer9 </option>
                          <option value="ans10"> Answer10 </option>

                          <option value="date">Date </option>
                        </select>
                      </div>
                      {errors.fieldtype && touched.fieldtype ? (
                        <span className="text-danger">{errors.fieldtype}</span>
                      ) : null}
                    </div>

                    <div className="col-lg-6">
                      <div className="input-field-box mb-lg-0">
                        <label>Search</label>
                        <div className="input-group">
                          <input
                            type={values.fieldtype === "date" ? "date" : "text"}
                            className={`form-control ${
                              errors.searchval && touched.searchval
                                ? "is-danger"
                                : ""
                            }`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="searchval"
                            value={values.searchval || ""}
                            required
                          />

                          <button className="btn btn-primary " type="submit">
                            Search
                          </button>
                          <button
                            className="btn btn-primary"
                            type="reset"
                            onClick={() => window.location.reload(false)}
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                      {errors.searchval && touched.searchval ? (
                        <span className="text-danger">{errors.searchval}</span>
                      ) : null}
                    </div>
                    <div className="col-md-3">
                      <div className="input-field-box mb-0">
                        <label>Show Entries</label>
                        <select
                          className="form-select"
                          value={limit}
                          onChange={(e) =>
                            surveyListSubmitted("1", e.target.value, values)
                          }
                        >
                          <option value="10">10</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="250">250</option>
                          <option value="500">500</option>
                        </select>
                      </div>
                    </div>
                  </form>
                </div>
                {tableLoader ? (
                  <div className="card-body pt-0">
                    <Skeleton count={11} height="50px" />
                  </div>
                ) : (
                  <div className="table-responsive">
                    <table className="text-nowrap  table">
                      <thead className="table-light">
                        <tr>
                          <th scope="col">Sr#</th>
                          <th scope="col">Survey</th>
                          <th scope="col">Date</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData.length ? (
                          tableData.map((el, index) => (
                            <tr key={index}>
                              <td className="align-middle">
                                {currentPage === 1
                                  ? index + 1
                                  : (currentPage - 1) * limit + index + 1}
                              </td>

                              <td className="align-middle">
                                <strong>Email:</strong>{" "}
                                <span className="text-control d-inline-block">
                                  {el.email}
                                </span>
                                <hr />
                                <strong className="d-block">
                                  Q1. Overall, how satisfied or dissatisfied are
                                  you with our company?
                                </strong>
                                <strong className="vl-top">Answer1.</strong>{" "}
                                <span className="text-control d-inline-block">
                                  {el.ans1}
                                </span>
                                <hr />
                                <strong className="d-block">
                                  Q2. Which of the following words would you use
                                  to describe our products? Select all that
                                  apply.
                                </strong>
                                <strong className="vl-top">Answer2.</strong>{" "}
                                <span className="text-control d-inline-block">
                                  {el.ans2}
                                </span>
                                <hr />
                                <strong className="d-block">
                                  Q3.How well do our products meet your needs?
                                </strong>
                                <strong className="vl-top">Answer3.</strong>{" "}
                                <span className="text-control d-inline-block">
                                  {el.ans3}
                                </span>
                                <hr />
                                <strong className="d-block">
                                  Q4. How would you rate the quality of our
                                  products?
                                </strong>
                                <strong className="vl-top">Answer4.</strong>{" "}
                                <span className="text-control d-inline-block">
                                  {el.ans4}
                                </span>
                                <hr />
                                <strong className="d-block">
                                  Q5. How would you rate the value for money of
                                  our products?
                                </strong>
                                <strong className="vl-top">Answer5.</strong>{" "}
                                <span className="text-control d-inline-block">
                                  {el.ans5}
                                </span>
                                <hr />
                                <strong className="d-block">
                                  Q6. How responsive have we been to your
                                  questions or concerns about our products?
                                </strong>
                                <strong className="vl-top">Answer6.</strong>{" "}
                                <span className="text-control d-inline-block">
                                  {el.ans6}
                                </span>
                                <hr />
                                <strong className="d-block">
                                  Q7. How long have you been a customer of our
                                  company?
                                </strong>
                                <strong className="vl-top">Answer7.</strong>{" "}
                                <span className="text-control d-inline-block">
                                  {el.ans7}
                                </span>
                                <hr />
                                <strong className="d-block">
                                  Q8.How likely are you to purchase any of our
                                  products again?
                                </strong>
                                <strong className="vl-top">Answer8.</strong>{" "}
                                <span className="text-control d-inline-block">
                                  {el.ans8}
                                </span>
                                <hr />
                                <strong className="d-block">
                                  Q9.How likely is it that you would recommend
                                  this company to a friend or colleague?
                                </strong>
                                <strong className="vl-top">Answer9.</strong>{" "}
                                <span className="text-control d-inline-block">
                                  {el.ans9}
                                </span>
                                <hr />
                                <strong className="d-block">
                                  Q10. Do you have any other comments,
                                  questions, or concerns?
                                </strong>
                                <strong className="vl-top">Answer10.</strong>{" "}
                                <span className="text-control d-inline-block">
                                  {el.ans10}
                                </span>
                              </td>
                              <td className="align-middle ">
                                <span className="text-control">
                                  {moment(el.created_at).format("MM-DD-YYYY")}
                                </span>
                              </td>
                              <td className="align-middle ">
                                <div className="d-flex align-items-center">
                                  <button
                                    onClick={() => {
                                      archiveSurvey(el.id);
                                    }}
                                    className="btn btn-success btn-sm btn-icon-text"
                                  >
                                    Archive <i className="bi bi bi-archive"></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan="6"
                              className="text-center text-capitalize"
                            >
                              No record found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    {totalResults > limit && totalPages > 1 ? (
                      <Pagination
                        activePage={currentPage}
                        itemsCountPerPage={parseInt(limit)}
                        totalItemsCount={totalResults}
                        onChange={(e) => {
                          surveyListSubmitted(e, limit, values);
                        }}
                        pageRangeDisplayed={8}
                        itemClass="page-item"
                        linkClass="page-link"
                        firstPageText="First Page"
                        lastPageText="Last Page"
                      />
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
