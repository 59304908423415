import axios from "axios";

const API_URL = process.env.REACT_APP_API_Link;

const headers2 = {
  "Content-Type": "application/json",
};

const registerForm = (certificate) => {
  return axios.get(API_URL + `validate-voucher-ps/${certificate}`, {
    headers: headers2,
  });
};

const getStates = () => {
  return axios.get(API_URL + `states`, {
    headers: headers2,
  });
};
const getGifts = (certificate) => {
  return axios.get(API_URL + `product-by-voucher/${certificate}`, {
    headers: headers2,
  });
};

const getSize = (certificate) => {
  return axios.get(API_URL + `sheet-by-voucher/${certificate}`, {
    headers: headers2,
  });
};

const submitVoucher = (values) => {
  return axios.post(API_URL + "submit-voucher-ps", values, {
    headers: headers2,
  });
};

const UserListService = { registerForm, getStates, getGifts, getSize, submitVoucher };

export default UserListService;
