import React, { useEffect } from "react";
import Sidebar from "../includes/Sidebar";
import Footer from "../includes/Footer";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { useState } from "react";
import AdminListService from "../../../services/admin-list.service";

import MainHeading from "../includes/MainHeading";
import Skeleton from "react-loading-skeleton";

import Pagination from "react-js-pagination";
import { useFormik } from "formik";
import { quizSearch } from "../../../schema";
import moment from "moment";
import formatThousands from "format-thousands";

export default function ManageJobs() {
  const [tableLoader, settableLoader] = useState(false);

  const [tableData, settableData] = useState([]);
  const [limit, setLimit] = useState("10");
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [years, setYears] = useState([]);
  const [currentDateTime, setCurrentDateTime] = useState("");

  const [currentPage, setCurrentPage] = useState(1);

  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("0");

  const state = useSelector((state) => state.stateVals);
  const { adminAccessToken } = state;
  const navigate = useNavigate();

  const jobList = async (page, limit, values, year) => {
    settableLoader(true);
    try {
      const responce = await AdminListService.getJobss(
        page,
        limit,
        values,
        year
      );

      const res = responce?.data?.response;

      const results = [];
      res.map((value) => {
        return results.push({
          job: value.job,
          job_date: value.job_date,
          job_percentage: value.job_percentage,
          total_cert: value.total_cert,
          total_used_cert: value.total_used_cert,
        });
      });

      settableData([...results]);
      setTotalPages(responce?.data?.total_pages);
      setTotalResults(responce?.data?.records);
      setCurrentPage(responce?.data?.current_page);
      setLimit(responce?.data?.per_page);

      settableLoader(false);
    } catch (err) {
      console.log(err);
      settableLoader(false);
      settableData([]);
      setTotalPages("0");
      setTotalResults("0");
    }
  };

  useEffect(() => {
    if (adminAccessToken) {
      jobList("1", limit, [], currentYear);
      const yearList = async () => {
        try {
          const responce = await AdminListService.getAllYears();

          const res = responce?.data?.response;

          const results = [];
          res.map((value) => {
            return results.push({
              year: value[0],
            });
          });

          setYears([...results]);
        } catch (err) {
          console.log(err);
          setYears([]);
        }
      };
      yearList();
      const updateTimestamp = () => {
        const timestampInSeconds = Math.floor(Date.now() / 1000);
        setCurrentDateTime(timestampInSeconds);
      };

      const intervalId = setInterval(updateTimestamp, 1000);

      return () => clearInterval(intervalId);
    } else {
      navigate("/admin/login", { replace: true });
    }

    // eslint-disable-next-line
  }, []);

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,

      initialValues: {
        fieldtype: "job",
        searchval: "",
      },
      validationSchema: quizSearch,
      onSubmit: (values) => {
        jobList("1", limit, values, currentYear);
      },
    });

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <Sidebar />
          <MainHeading heading="Manage Your Jobs" />

          <div className="mt-4 row">
            <div className="col-md-12 col-12">
              <div className="card dashboard-cards">
                <div className="bg-white  py-3 card-header">
                  <div className="row">
                    <div className="col-md-8">
                      <h4 className="mb-0">
                        Total Jobs:{" "}
                        <span className="text-primary-color fw-bold">
                          {formatThousands(totalResults, {
                            separator: ",",
                          })}
                        </span>
                      </h4>
                    </div>
                    <div className="col-md-4 text-end">
                      <a
                        className="btn btn-primary btn-sm btn-icon-text"
                        href={`${process.env.REACT_APP_API_Link}export-job/${currentYear}?time=${currentDateTime}`}
                      >
                        Export Jobs Summary{" "}
                        <i className="bi bi-filetype-exe"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <form className="row" onSubmit={handleSubmit} noValidate>
                    <div className="col-lg-3">
                      <div className="input-field-box mb-lg-0">
                        <label>Show Column</label>
                        <select
                          className={`form-select ${
                            errors.fieldtype && touched.fieldtype
                              ? "is-danger"
                              : ""
                          }`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="fieldtype"
                          value={values.fieldtype || ""}
                          required
                        >
                          <option value="job">Job </option>
                        </select>
                      </div>
                      {errors.fieldtype && touched.fieldtype ? (
                        <span className="text-danger">{errors.fieldtype}</span>
                      ) : null}
                    </div>

                    <div className="col-lg-5">
                      <div className="input-field-box mb-lg-0">
                        <label>Search</label>
                        <div className="input-group">
                          <input
                            type="text"
                            className={`form-control ${
                              errors.searchval && touched.searchval
                                ? "is-danger"
                                : ""
                            }`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="searchval"
                            value={values.searchval || ""}
                            required
                          />

                          <button className="btn btn-primary " type="submit">
                            Search
                          </button>
                          <button
                            className="btn btn-primary"
                            type="reset"
                            onClick={() => window.location.reload(false)}
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                      {errors.searchval && touched.searchval ? (
                        <span className="text-danger">{errors.searchval}</span>
                      ) : null}
                    </div>
                    <div className="col-lg-2">
                      <div className="input-field-box mb-0">
                        <label>Show Entries By Year</label>
                        <select
                          className="form-select"
                          value={currentYear}
                          onChange={(e) => {
                            setCurrentYear(e.target.value);
                            jobList("1", limit, values, e.target.value);
                          }}
                        >
                          {years.length
                            ? years.map((el, index) => (
                                <option value={el.year} key={index}>
                                  {el.year}
                                </option>
                              ))
                            : null}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <div className="input-field-box mb-0">
                        <label>Show Entries</label>
                        <select
                          className="form-select"
                          value={limit}
                          onChange={(e) =>
                            jobList("1", e.target.value, values, currentYear)
                          }
                        >
                          <option value="10">10</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="250">250</option>
                          <option value="500">500</option>
                        </select>
                      </div>
                    </div>
                  </form>
                </div>
                {tableLoader ? (
                  <div className="card-body pt-0">
                    <Skeleton count={11} height="50px" />
                  </div>
                ) : (
                  <div className="table-responsive">
                    <table className="text-nowrap  table">
                      <thead className="table-light">
                        <tr>
                          <th scope="col">No</th>

                          <th scope="col">Job Number</th>

                          <th scope="col">Total Certificates</th>
                          <th scope="col">Total Used Certificates</th>
                          <th scope="col">Percentage Used Certificates</th>
                          <th scope="col">Creation Date</th>
                          <th scope="col">Begin Redemption</th>
                          <th scope="col">Expiration</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData.length ? (
                          tableData.map((el, index) => (
                            <tr key={index}>
                              <td className="align-middle">
                                {currentPage === 1
                                  ? index + 1
                                  : (currentPage - 1) * limit + index + 1}
                              </td>

                              <td className="align-middle">
                                <Link className="job-no" to={`/admin/job-detail/${el.job}`}>
                                  {el.job}
                                </Link>
                              </td>
                              <td className="align-middle">{el.total_cert}</td>
                              <td className="align-middle">
                                {el.total_used_cert}
                              </td>
                              <td className="align-middle">
                                {el.job_percentage}
                              </td>
                              <td className="align-middle">
                              {el.job_date[0]?.created_at &&
                                el.job_date[0]?.created_at !== "" &&
                                el.job_date[0]?.created_at !== "0000-00-00"
                                  ? moment(el.job_date[0]?.created_at).format(
                                      "MM-DD-YYYY"
                                    )
                                  : "N/A"}
                              </td>
                              <td className="align-middle">
                                {el.job_date[0]?.issuance &&
                                el.job_date[0]?.issuance !== "" &&
                                el.job_date[0]?.issuance !== "0000-00-00"
                                  ? moment(el.job_date[0]?.issuance).format(
                                      "MM-DD-YYYY"
                                    )
                                  : "N/A"}
                              </td>
                             
                              <td className="align-middle">
                                {el.job_date[0]?.expiration &&
                                el.job_date[0]?.expiration !== "" &&
                                el.job_date[0]?.expiration !== "0000-00-00"
                                  ? moment(el.job_date[0]?.expiration).format(
                                      "MM-DD-YYYY"
                                    )
                                  : null}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan="6"
                              className="text-center text-capitalize"
                            >
                              No record found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    {totalResults > limit && totalPages > 1 ? (
                      <Pagination
                        activePage={currentPage}
                        itemsCountPerPage={parseInt(limit)}
                        totalItemsCount={totalResults}
                        onChange={(e) => {
                          jobList(e, limit, values, currentYear);
                        }}
                        pageRangeDisplayed={8}
                        itemClass="page-item"
                        linkClass="page-link"
                        firstPageText="First Page"
                        lastPageText="Last Page"
                      />
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
