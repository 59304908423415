import { useEffect, useReducer, useRef, useState } from "react";

import "./style.css";
import Footer from "./Footer";
import SurvayPage from "./SurvayPage";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";

import UserListService from "../../services/user-list.service";

import secureLocalStorage from "react-secure-storage";
import Select from "react-select";
import LoadingBox from "./LoadingBox";
import MessageBox from "./MessageBox";
import Header from "./Header";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loading: true };
    case "FETCH_SUCCESS":
      return { ...state, data: action.payload, loading: false };
    case "FETCH_FAIL":
      return { ...state, error: action.payload, loading: false };
    case "FETCH_FAIL":
      return { ...state, error1: action.payload, loading: false };
    default:
      return state;
  }
};

function UserForm() {
  const [fields, setFields] = useState({
    name: "",
    lname: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    zipcode: "",
    phonenumber: "",
    phonenumber1: "",
    phonenumber2: "",
    emailid: "",
  });

  const [{ loading, error, data }, dispatch] = useReducer(reducer, {
    data: [],
    loading: true,
    error: "",
  });
  const navigate = useNavigate();

  const [errors, setErrors] = useState({});
  const [states, setStates] = useState([]);

  const [sheet, setSheet] = useState([]);
  const [products, setProducts] = useState([]);

  const [i_agree_five, setI_agree_five] = useState(false);
  const [showPage, setShowPage] = useState(false);

  // const [signoutTime , setSignoutTime] = useState(1000 * 60 * 10);
  const signoutTime = 980000;

  const [selectGift, setSelectGift] = useState("");
  const [selectSize, setSelectSize] = useState("");

  const ref = useRef();

  async function test() {
    try {
      const response = await UserListService.getStates();

      var states = response?.data?.response;
      const results = [];
      states.map((value) => {
        return results.push({
          value: value.code,
          label: value.name,
        });
      });
      await setStates([...results]);
    } catch (err) {
      if (err.status === 429 || err.status === "429") {
        alert("Max Limit Reached. Please wait.");
      }
    }

    try {
      const responseSheet = await UserListService.getGifts(
        localStorage.getItem("voucher")
      );

      const products = responseSheet.data.product;

      setProducts(products);
      errors["voucher"] = responseSheet.data.response;
      dispatch({ type: "FETCH_SUCCESS", payload: products });
    } catch (err) {
      if (err.status === 429 || err.status === "429") {
        alert("Max Limit Reached. Please wait.");
      } else if (err.message.includes("400")) {
        dispatch({
          type: "FETCH_FAIL",
          payload: "Failed to load cards",
        });
      } else {
        dispatch({ type: "FETCH_FAIL", payload: err.message });
      }
    }

    try {
      const responseSheet = await UserListService.getSize(
        localStorage.getItem("voucher")
      );

      const sheet = responseSheet.data.sheet;

      setSheet(sheet);
      errors["voucher"] = responseSheet.data.response;
      dispatch({ type: "FETCH_SUCCESS", payload: sheet });
    } catch (err) {
      if (err.status === 429 || err.status === "429") {
        alert("Max Limit Reached. Please wait.");
      } else if (err.message.includes("400")) {
        dispatch({
          type: "FETCH_FAIL",
          payload: "Failed to load cards",
        });
      } else {
        // dispatch({ type: "FETCH_FAIL", payload: err.message });
      }
    }
  }
  useEffect(() => {
    const Voucher = localStorage.getItem("voucher");

    if (
      Voucher === undefined ||
      Voucher === "undefined" ||
      Voucher === "null" ||
      Voucher === null
    ) {
      localStorage.setItem("returnpage", false);
      navigate("/");
    } else {
      setShowPage(true);
      timeout();
      test();
      localStorage.setItem("returnpage", true);
      const keysToKeep = [
        "voucher",
        "Survey",
        "isVoucher",
        "returnpage",
        "returnpage",
        "Logo",
      ]; // Specify the keys you want to keep
      function clearLocalStorageExcept(keysToKeep) {
        for (let key in localStorage) {
          if (!keysToKeep.includes(key)) {
            localStorage.removeItem(key);
          }
        }
      }

      clearLocalStorageExcept(keysToKeep);
    }
    // eslint-disable-next-line
  }, []);

  function timeout() {
    setTimeout(function () {
      logout();
    }, signoutTime);
  }

  const logout = () => {
    destroy();
  };

  const destroy = () => {
    localStorage.setItem("returnpage", false);
    localStorage.removeItem("voucher");
    window.location.replace("/");
  };

  const handleChange = (e) => {
    let { value, name } = e.target;

    setFields((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const handleChangecheckboxthree = (e, value) => {
    let sizeName = e.target.value;
    setSelectSize(sizeName);
    setI_agree_five(!i_agree_five);
  };
  const handleChangecheckboxfive = (e, value) => {
    let giftName = e.target.value;
    setSelectGift(giftName);
    setI_agree_five(!i_agree_five);
  };

  const submituserRegistrationForm = (e) => {
    e.preventDefault();
    if (validate()) {
      let fields = {};
      fields["voucher"] = "";
      fields["name"] = "";
      fields["lname"] = "";
      fields["address"] = "";
      fields["address2"] = "";
      fields["city"] = "";
      fields["state"] = "";
      fields["zipcode"] = "";
      fields["phonenumber"] = "";
      fields["phonenumber1"] = "";
      fields["phonenumber2"] = "";
      fields["emailid"] = "";
      fields["checkederror"] = "";
      fields["checkederrortwo"] = "";
      fields["checkederrorfive"] = "";
    }
  };

  const validate = async () => {
    const First = localStorage.getItem("First")
      ? localStorage.getItem("First") + " "
      : "";
    const Second = localStorage.getItem("Second")
      ? localStorage.getItem("Second") + " "
      : "";
    const Third = localStorage.getItem("Third")
      ? localStorage.getItem("Third") + " "
      : "";
    const Four = localStorage.getItem("Four")
      ? localStorage.getItem("Four") + " "
      : "";
    const Five = localStorage.getItem("Five")
      ? localStorage.getItem("Five") + " "
      : "";
    const Six = localStorage.getItem("Six")
      ? localStorage.getItem("Six") + " "
      : "";
    const Seven = localStorage.getItem("Seven")
      ? localStorage.getItem("Seven") + " "
      : "";
    const Eight = localStorage.getItem("Eight")
      ? localStorage.getItem("Eight") + " "
      : "";
    const Nine = localStorage.getItem("Nine")
      ? localStorage.getItem("Nine") + " "
      : "";
    const Ten = localStorage.getItem("Ten")
      ? localStorage.getItem("Ten") + " "
      : "";

    let isformIsValid = false;

    const All_Data =
      First +
      "" +
      Second +
      "" +
      Third +
      "" +
      Four +
      "" +
      Five +
      "" +
      Six +
      "" +
      Seven +
      "" +
      Eight +
      "" +
      Nine +
      "" +
      Ten;

    let data = {
      // ans1: localStorage.getItem("ans1") ? localStorage.getItem("ans1") : "",
      // ans3: localStorage.getItem("ans3") ? localStorage.getItem("ans3") : "",
      // ans4: localStorage.getItem("ans4") ? localStorage.getItem("ans4") : "",
      // ans5: localStorage.getItem("ans5") ? localStorage.getItem("ans5") : "",
      // ans6: localStorage.getItem("ans6") ? localStorage.getItem("ans6") : "",
      // ans7: localStorage.getItem("ans7") ? localStorage.getItem("ans7") : "",
      // ans8: localStorage.getItem("ans8") ? localStorage.getItem("ans8") : "",
      // ans9: localStorage.getItem("ans9") ? localStorage.getItem("ans9") : "",
      // ans10: localStorage.getItem("ans10") ? localStorage.getItem("ans10") : "",
      // ans2: All_Data,

      fname: fields.name,

      lname: fields.lname,
      address: fields.address,
      address2: fields.address2,
      city: fields.city,
      // color: state?.selectcard,
      email: fields?.emailid,
      phone: fields.phonenumber + fields.phonenumber1 + fields.phonenumber2,

      state: fields.state,
      zip: fields.zipcode,
      product: selectGift,
      sheet: selectSize,
      certificate: localStorage.getItem("voucher"),
    };

    try {
      const response = await UserListService.submitVoucher(data);

      isformIsValid = true;

      localStorage.setItem("isVoucher", true);
      localStorage.setItem("returnpage", false);
      secureLocalStorage.setItem(
        "UserForm",
        JSON.stringify({
          name: fields.name,
          lname: fields.lname,
          address: fields.address,
          address2: fields.address2,
          city: fields.city,
          state: fields.state,
          zipcode: fields.zipcode,
          phonenumber: fields.phonenumber,
          phonenumber1: fields.phonenumber1,
          phonenumber2: fields.phonenumber2,
          emailid: fields.emailid,
          card: selectGift,
          size: selectSize,
        })
      );
      navigate("/thank-you");
    } catch (err) {
      if (err.response.status === 429 || err.response.status === "429") {
        alert("Max Limit Reached. Please wait.");
      }
      isformIsValid = false;
      let errorData = {};
      errorData["name"] =
        err.response?.data &&
        err.response?.data?.fname &&
        err.response?.data?.fname[0];
      errorData["lname"] =
        err.response?.data &&
        err.response?.data?.lname &&
        err.response?.data?.lname[0];
      errorData["address"] =
        err.response?.data &&
        err.response?.data?.address &&
        err.response?.data?.address[0];
      errorData["address2"] =
        err.response?.data &&
        err.response?.data?.address2 &&
        err.response?.data?.address2[0];
      errorData["city"] =
        err.response?.data &&
        err.response?.data?.city &&
        err.response?.data?.city[0];
      errorData["state"] =
        err.response?.data &&
        err.response?.data?.state &&
        err.response?.data?.state[0];
      errorData["zipcode"] =
        err.response?.data &&
        err.response?.data?.zip &&
        err.response?.data?.zip[0];
      errorData["phonenumber"] =
        err.response?.data &&
        err.response?.data?.phone &&
        err.response?.data?.phone[0];
      errorData["emailid"] =
        err.response?.data &&
        err.response?.data?.email &&
        err.response?.data?.email[0];

      errorData["checkederror"] =
        err.response?.data &&
        err.response?.data?.product &&
        err.response?.data?.product[0];
      errorData["checkederrortwo"] =
        err.response?.data &&
        err.response?.data?.sheet &&
        err.response?.data?.sheet[0];
      errorData["checkederrorfive"] =
        err.response?.data &&
        err.response?.data?.product &&
        err.response?.data?.product[0];

      errorData["certificate"] =
        err.response?.data &&
        err.response?.data?.certificate &&
        err.response?.data?.certificate[0];

      setErrors(errorData);
      setTimeout(() => {
        ref?.current?.focus();
      }, 200);
    }
    return isformIsValid;
  };

  const GuestGreeting = () => {
    if (localStorage.getItem("Survey") === "yes") {
      return <SurvayPage />;
    }
  };

  const onlyNumbers = (evt, nextField) => {
    const { name, value } = evt.target;
    evt.target.value = evt.target.value.replace(/[^0-9]+/g, "");

    setFields((prevValue) => {
      return {
        ...prevValue,
        [name]: evt.target.value,
      };
    });
    if (evt.target.value !== "" && nextField && nextField !== "") {
      moveOnMax(evt, nextField);
    }
  };
  const moveOnMax = (field, nextFieldID) => {
    if (field.target.value.length >= field.target.maxLength) {
      document.getElementById(nextFieldID).focus();
    }
  };

  const pasted = (e) => {
    e.preventDefault();
    return false;
  };

  // const colourStyles = {
  //   option: (styles, { data, isDisabled, isFocused, isSelected }) => {
  //     // const color = chroma(data.color);
  //     console.log({ data, isDisabled, isFocused, isSelected });
  //     return {
  //       ...styles,
  //       backgroundColor: isFocused ? "#2384d3" : null,
  //       color: "#333333"
  //     };
  //   }
  // };
  return (
    <>
      {!showPage && <div className="showPage"></div>}
      <div className="container-fluid">
        <Header />
        <div id="main-registration-container">
          <div className="white-box form">
            {/* <h1>Reward Card Redemption Site</h1> */}
            <h2>Contact Information</h2>
            {/* {GuestGreeting()} */}

            <form
              name="userRegistrationForm"
              className="form-outer"
              onSubmit={submituserRegistrationForm}
            >
              <div className="row">
                <div className="col-md-6">
                  <label className="formsub">
                    {" "}
                    First Name <span className="red_point">*</span>{" "}
                  </label>
                  <input
                    type="text"
                    placeholder="First Name"
                    style={errors.name ? { border: "1px solid red" } : null}
                    name="name"
                    id="fname"
                    className="searchBox_deals vc-validate"
                    ref={errors.name ? ref : null}
                    value={fields.name}
                    autoFocus=""
                    onChange={handleChange}
                  />
                  <div className="errorMsg">{errors.name}</div>
                </div>
                <div className="col-md-6">
                  <label className="formsub">
                    {" "}
                    Last Name <span className="red_point">*</span>{" "}
                  </label>
                  <input
                    type="text"
                    name="lname"
                    style={
                      errors.lname && !errors.name
                        ? { border: "1px solid red" }
                        : null
                    }
                    placeholder="Last Name"
                    id="lname"
                    className="searchBox_deals vc-validate"
                    value={fields.lname}
                    ref={errors.lname && !errors.name ? ref : null}
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.lname && !errors.name ? errors.lname : null}
                  </div>
                </div>

                <div className="clearfix"></div>

                <div className="col-md-6">
                  <label required className="formsub">
                    Address1 <span className="red_point">*</span>
                  </label>

                  <input
                    type="text"
                    style={
                      errors.address && !errors.lname && !errors.name
                        ? { border: "1px solid red" }
                        : null
                    }
                    name="address"
                    placeholder="Address1"
                    id="address"
                    ref={
                      errors.address && !errors.lname && !errors.name
                        ? ref
                        : null
                    }
                    className="searchBox_deals vc-validate"
                    autoFocus=""
                    value={fields.address}
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.address && !errors.lname && !errors.name
                      ? errors.address
                      : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="formsub">Address2</label>

                  <input
                    type="text"
                    style={
                      errors.address2 &&
                      !errors.address &&
                      !errors.lname &&
                      !errors.name
                        ? { border: "1px solid red" }
                        : null
                    }
                    name="address2"
                    placeholder="Address 2"
                    id="address2"
                    className="searchBox_deals vc-validate"
                    autoFocus=""
                    value={fields.address2}
                    onChange={handleChange}
                    ref={
                      errors.address2 &&
                      !errors.address &&
                      !errors.lname &&
                      !errors.name
                        ? ref
                        : null
                    }
                  />
                  <div className="errorMsg">
                    {errors.address2 &&
                    !errors.address &&
                    !errors.lname &&
                    !errors.name
                      ? errors.address2
                      : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="formsub">
                    {" "}
                    City <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    id="city"
                    name="city"
                    style={
                      errors.city &&
                      !errors.address &&
                      !errors.lname &&
                      !errors.name
                        ? { border: "1px solid red" }
                        : null
                    }
                    placeholder="City"
                    ref={
                      errors.city &&
                      !errors.address &&
                      !errors.lname &&
                      !errors.name
                        ? ref
                        : null
                    }
                    value={fields.city}
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.city &&
                    !errors.address &&
                    !errors.lname &&
                    !errors.name
                      ? errors.city
                      : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="formsub">
                    State <span className="red_point">*</span>
                  </label>

                  <Select
                    classNamePrefix="react-select"
                    className={`select_1 dropdown ${
                      errors.state &&
                      !errors.city &&
                      !errors.address &&
                      !errors.lname &&
                      !errors.name
                        ? "error-select-search"
                        : ""
                    }`}
                    placeholder="Select State"
                    // styles={colourStyles}
                    options={states}
                    isSearchable={true}
                    isClearable={true}
                    name="state"
                    ref={
                      errors.state &&
                      !errors.city &&
                      !errors.address &&
                      !errors.lname &&
                      !errors.name
                        ? ref
                        : null
                    }
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        setFields((prevValue) => {
                          return {
                            ...prevValue,
                            ["state"]: selectedOption.value,
                          };
                        });
                      } else {
                        setFields((prevValue) => {
                          return {
                            ...prevValue,
                            ["state"]: "",
                          };
                        });
                      }
                    }}
                  />

                  <div className="errorMsg">
                    {errors.state &&
                    !errors.city &&
                    !errors.address &&
                    !errors.lname &&
                    !errors.name
                      ? errors.state
                      : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="formsub">
                    Zip Code <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    id="zipcode"
                    style={
                      errors.zipcode &&
                      !errors.state &&
                      !errors.city &&
                      !errors.address &&
                      !errors.lname &&
                      !errors.name
                        ? { border: "1px solid red" }
                        : null
                    }
                    placeholder="Zip/Postal Code"
                    name="zipcode"
                    maxLength="10"
                    value={fields.zipcode}
                    ref={
                      errors.zipcode &&
                      !errors.state &&
                      !errors.city &&
                      !errors.address &&
                      !errors.lname &&
                      !errors.name
                        ? ref
                        : null
                    }
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.zipcode &&
                    !errors.state &&
                    !errors.city &&
                    !errors.address &&
                    !errors.lname &&
                    !errors.name
                      ? errors.zipcode
                      : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="phone-outer">
                    <label className="formsub">
                      Phone No <span className="red_point">*</span>
                    </label>
                    <input
                      type="text"
                      className="phone"
                      style={
                        errors.phonenumber &&
                        fields?.phonenumber?.length < 3 &&
                        !errors.state &&
                        !errors.zipcode &&
                        !errors.city &&
                        !errors.address &&
                        !errors.lname &&
                        !errors.name
                          ? { border: "1px solid red" }
                          : null
                      }
                      ref={
                        errors.phonenumber &&
                        fields?.phonenumber?.length < 3 &&
                        !errors.state &&
                        !errors.zipcode &&
                        !errors.city &&
                        !errors.address &&
                        !errors.lname &&
                        !errors.name
                          ? ref
                          : null
                      }
                      maxLength="3"
                      id="phonenumber"
                      name="phonenumber"
                      onKeyUp={(e) => {
                        onlyNumbers(e, "phonenumber1");
                      }}
                      onPaste={pasted}
                      // onKeyUp="moveOnMax(this,'phonenumber1')"
                      value={fields.phonenumber}
                      onChange={handleChange}
                    />{" "}
                    -
                    <input
                      type="text"
                      className="phone"
                      style={
                        errors.phonenumber &&
                        fields.phonenumber !== "" &&
                        fields?.phonenumber1?.length < 3 &&
                        fields?.phonenumber?.length === 3 &&
                        !errors.state &&
                        !errors.zipcode &&
                        !errors.city &&
                        !errors.address &&
                        !errors.lname &&
                        !errors.name
                          ? { border: "1px solid red" }
                          : null
                      }
                      ref={
                        errors.phonenumber &&
                        fields.phonenumber !== "" &&
                        fields?.phonenumber1?.length < 3 &&
                        fields?.phonenumber?.length === 3 &&
                        !errors.state &&
                        !errors.zipcode &&
                        !errors.city &&
                        !errors.address &&
                        !errors.lname &&
                        !errors.name
                          ? ref
                          : null
                      }
                      maxLength="3"
                      id="phonenumber1"
                      name="phonenumber1"
                      // onKeyUp="moveOnMax(this,'phonenumber2')"
                      value={fields.phonenumber1}
                      onChange={handleChange}
                      onKeyUp={(e) => {
                        onlyNumbers(e, "phonenumber2");
                      }}
                      onPaste={pasted}
                    />{" "}
                    -
                    <input
                      type="text"
                      className="phone"
                      style={
                        errors.phonenumber &&
                        fields?.phonenumber2?.length < 4 &&
                        fields.phonenumber1 !== "" &&
                        fields.phonenumber !== "" &&
                        fields?.phonenumber?.length === 3 &&
                        !errors.state &&
                        !errors.zipcode &&
                        !errors.city &&
                        !errors.address &&
                        !errors.lname &&
                        !errors.name
                          ? { border: "1px solid red" }
                          : null
                      }
                      ref={
                        errors.phonenumber &&
                        fields?.phonenumber2?.length < 4 &&
                        fields.phonenumber1 !== "" &&
                        fields.phonenumber !== "" &&
                        fields?.phonenumber?.length === 3 &&
                        fields?.phonenumber1?.length === 3 &&
                        !errors.state &&
                        !errors.zipcode &&
                        !errors.city &&
                        !errors.address &&
                        !errors.lname &&
                        !errors.name
                          ? ref
                          : null
                      }
                      maxLength="4"
                      id="phonenumber2"
                      name="phonenumber2"
                      // onKeyUp="moveOnMax(this,'email')"
                      value={fields.phonenumber2}
                      onChange={handleChange}
                      onKeyUp={onlyNumbers}
                      onPaste={pasted}
                    />
                    <div className="errorMsg">
                      {errors.phonenumber &&
                      !errors.zipcode &&
                      !errors.state &&
                      !errors.city &&
                      !errors.address &&
                      !errors.lname &&
                      !errors.name
                        ? errors.phonenumber
                        : null}
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="formsub">
                    Email <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    name="emailid"
                    placeholder="E-mail"
                    ref={
                      errors.emailid &&
                      !errors.state &&
                      !errors.phonenumber &&
                      !errors.zipcode &&
                      !errors.city &&
                      !errors.address &&
                      !errors.lname &&
                      !errors.name
                        ? ref
                        : null
                    }
                    style={
                      errors.emailid &&
                      !errors.state &&
                      !errors.phonenumber &&
                      !errors.zipcode &&
                      !errors.city &&
                      !errors.address &&
                      !errors.lname &&
                      !errors.name
                        ? { border: "1px solid red" }
                        : null
                    }
                    id="emailid"
                    // placeholder="E-mail"
                    value={fields.emailid}
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.emailid &&
                    !errors.phonenumber &&
                    !errors.state &&
                    !errors.zipcode &&
                    !errors.city &&
                    !errors.address &&
                    !errors.lname &&
                    !errors.name
                      ? errors.emailid
                      : null}
                  </div>
                </div>

                <h2>Please select the size and color you wish to receive</h2>

                <br />
                <div className="col-md-12">
                  <div className="row">
                    <h1 className="h1-deading"> Size</h1>
                    <span style={{ float: "left" }} id="color_msg22"></span>

                    <div className="errorMsg">{errors.checkederrortwo}</div>

                    <span
                      className="error_message_forimage"
                      id="color_msg22"
                    ></span>
                    {loading ? (
                      <LoadingBox />
                    ) : error ? (
                      <MessageBox variant="danger">{error}</MessageBox>
                    ) : (
                      sheet.map((row) => {
                        return (
                          <div className="col-md-4" key={row.name}>
                            <div className="adj-high">
                           
                                <span className="insdex size-check">
                                  <input
                                    type="radio"
                                    name="size"
                                    id={row.name}
                                    key={row.name}
                                    value={row.name}
                                    onChange={handleChangecheckboxthree}
                                  />
                                  {row.name}
                                </span>
                            </div>
                          </div>
                        );
                      })
                    )}
                  </div>
                </div>
                <hr />

                <div className="clearfix"></div>
                <div className="col-md-12">
                  <div className="row">
                    <h1 className="h1-deading"> Color</h1>
                    <span style={{ float: "left" }} id="color_msg22"></span>

                    <div className="errorMsg">{errors.checkederrorfive}</div>

                    <span
                      className="error_message_forimage"
                      id="color_msg22"
                    ></span>
                    {loading ? (
                      <LoadingBox />
                    ) : error ? (
                      <MessageBox variant="danger">{error}</MessageBox>
                    ) : (
                      products.map((insdex) => {
                        return (
                          <div className="col-md-4" key={insdex.name}>
                            <div className="adj-high">
                              <label>
                                <span className="insdex text-with-img">
                                  <input
                                    type="radio"
                                    name="color"
                                    id={insdex.name}
                                    key={insdex.name}
                                    value={insdex.name}
                                    onChange={handleChangecheckboxfive}
                                  />
                                  {insdex.name}
                                </span>
                                <img
                                  src={
                                    process.env.REACT_APP_API_GIFT_IMAGES +
                                    insdex.image
                                  }
                                  className="img-fluid sheet-color"
                                  style={{ height: "90px" }}
                                  alt="img..."
                                />
                              </label>
                            </div>
                          </div>
                        );
                      })
                    )}
                  </div>

                  {/* <span style={{ color: "red" }} id="clr_msg"></span> */}
                </div>

                <div></div>
              </div>
              <hr />

              <div className="col-md-12">
                {/* Survay */}

                {GuestGreeting()}
              </div>

              <button type="submit" name="submit" className="submit-btn">
                SUBMIT
              </button>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default UserForm;
