import React from "react";

import Footer from "./Footer";
import UserFormHeader from "./UserFormHeader";
import { Icon } from "@iconify/react";

import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import secureLocalStorage from "react-secure-storage";
import { useState } from "react";
import Header from "./Header";

function Thankyou() {
  const navigate = useNavigate();

  var redemptionForm = secureLocalStorage.getItem("UserForm");

  var redemptionObject = redemptionForm ? JSON.parse(redemptionForm) : false;

  const isVoucher = localStorage.getItem("isVoucher");
  const Voucher = localStorage.getItem("voucher");
  const [showPage, setShowPage] = useState(false);
  useEffect(() => {
    if (
      isVoucher === "false" ||
      Voucher === "undefined" ||
      isVoucher === false ||
      Voucher === undefined ||
      isVoucher === "null" ||
      Voucher === "null" ||
      isVoucher === null ||
      Voucher === null
    ) {
      localStorage.setItem("isVoucher", false);
      navigate("/");
    } else {
      localStorage.setItem("returnpage", false);
      localStorage.removeItem("voucher");
      setShowPage(true);
    }
    // eslint-disable-next-line
  }, []);
  return (
    <>
      {!showPage && <div className="showPage"></div>}
      <div className="container-fluid">
      <Header />

        <div className="white-box thank">
          <h1 className="text-center">Thank You! </h1>
          <Icon icon="zmdi:assignment-check" />
          <p>Thank You! {redemptionObject?.name}</p>
          {/* <p className="info">
            Your{" "}
            <span className="redstar" style={{ fontWeight: "500" }}>
              {redemptionObject?.card}
            </span>{" "}
            should arrive within 2 to 4 weeks.
          </p> */}
          {/* <p className="info">Your {props.history.location.ShowPage.gift} should arrive within 2 to 4 weeks.</p> */}
          {/* <p>Your Gift will be mailed to:</p> */}
          <ul className="info-data">
            <li key="uniqueId56">
              <b className="widthfont">Name: </b> {redemptionObject?.name}{" "}
              {redemptionObject?.lname}
            </li>
            <li key="23ff">
              <b className="widthfont">City: </b>
              {redemptionObject?.city}
            </li>
            <li key="uniqueId1">
              <b className="widthfont">State</b>: {redemptionObject.state}
            </li>
            <li>
              <b className="widthfont">Address</b>: {redemptionObject.address}
            </li>
            <li>
              <b className="widthfont">Zipcode</b>: {redemptionObject.zipcode}
            </li>
          </ul>
          <p>
            Once your order has processed, you will receive an email
            confirmation.
          </p>
          <p>
            Please do not discard your certificate until you receive your sheet.
          </p>
          <p>
            or email us at
            <a href="mailto:help@pickyoursheets.com">
              <strong className="blue"> help@pickyoursheets.com</strong>
            </a>
          </p>
          <p>and one of our sheet specialists will get back to you.</p>
          <p>Enjoy your sheet!</p>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default Thankyou;
