import React, { useEffect } from "react";
import Sidebar from "../includes/Sidebar";
import Footer from "../includes/Footer";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { useState } from "react";
import AdminListService from "../../../services/admin-list.service";

import MainHeading from "../includes/MainHeading";
import Skeleton from "react-loading-skeleton";
import Swal from "sweetalert2";
import Pagination from "react-js-pagination";
import { useFormik } from "formik";
import { quizSearch } from "../../../schema";
import moment from "moment";
import { toast } from "react-toastify";
import formatThousands from "format-thousands";

export default function ViewOrders() {
  const [tableLoader, settableLoader] = useState(false);

  const [tableData, settableData] = useState([]);
  const [limit, setLimit] = useState("10");
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  // const [currentYear, setCurrentYear] = useState(2022);
  const [years, setYears] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);

  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("0");

  const state = useSelector((state) => state.stateVals);
  const { adminAccessToken, admin_id } = state;
  const navigate = useNavigate();
  const [currentDateTime, setCurrentDateTime] = useState("");

  const orderList = async (page, limit, values, year) => {
    settableLoader(true);
    try {
      const responce = await AdminListService.getOrders(
        page,
        limit,
        values,
        "active",
        year
      );

      const res = responce?.data?.response;

      const results = [];
      res.map((value) => {
        return results.push({
          address1: value.address1,
          address2: value.address2,
          city: value.city,
          email: value.email,
          name: value.fname + " " + value.lname,
          size: value.size,
          gift: value.product,
          phone: value.phone,
          regDate: value.created_at,
          state: value.state,
          vocher: value.voucher,
          zip: value.zip,
          id: value.id,
        });
      });

      settableData([...results]);
      setTotalPages(responce?.data?.total_pages);
      setTotalResults(responce?.data?.records);
      setCurrentPage(responce?.data?.current_page);
      setLimit(responce?.data?.per_page);

      settableLoader(false);
    } catch (err) {
      console.log(err);
      settableLoader(false);
      settableData([]);
      setTotalPages("0");
      setTotalResults("0");
    }
  };

  useEffect(() => {
    if (adminAccessToken) {
      orderList("1", limit, [], currentYear);
      const yearList = async () => {
        try {
          const responce = await AdminListService.getAllYears();

          const res = responce?.data?.response;

          const results = [];
          res.map((value) => {
            return results.push({
              year: value[0],
            });
          });

          setYears([...results]);
        } catch (err) {
          console.log(err);
          setYears([]);
        }
      };
      yearList();

      const updateTimestamp = () => {
        const timestampInSeconds = Math.floor(Date.now() / 1000);
        setCurrentDateTime(timestampInSeconds);
      };

      const intervalId = setInterval(updateTimestamp, 1000);

      return () => clearInterval(intervalId);
    } else {
      navigate("/admin/login", { replace: true });
    }

    // eslint-disable-next-line
  }, []);

  const delOrder = async (delId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete record",
      icon: "question",
      iconColor: "#e04f5d",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      confirmButtonText: "Yes",
      cancelButtonText: "Close",
      customClass: {
        container: "del-popup", // Apply your custom class to the container
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await AdminListService.deletSingleOrders(
            delId,
            admin_id
          );

          orderList(currentPage, limit, values, currentYear);

          Swal.fire({
            icon: "success",
            title: "Deleted Successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
        } catch (err) {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            iconColor: "#e04f5d",
            confirmButtonColor: "#e72e2e",
            text: "Something went wrong!",
          });
        }
      }
    });
  };

  // const Table = ({ data }) => {
  //   return (
  //     <div className="table-responsive">
  //       <table className="text-nowrap  table">
  //         <thead className="table-light">
  //           <tr>
  //             <th scope="col">No</th>

  //             <th scope="col">Title</th>
  //             <th scope="col">Image</th>
  //             <th scope="col">Description</th>

  //             <th scope="col">Action</th>
  //           </tr>
  //         </thead>
  //         <tbody>
  //           {data.length ? (
  //             data.map((el, index) => (
  //               <tr key={index}>
  //                 <td className="align-middle">
  //                   {currentPage === 1
  //                     ? index + 1
  //                     : (currentPage - 1) * limit + index + 1}
  //                 </td>

  //                 <td className="align-middle">
  //                   <span className="text-control">{el.name}</span>
  //                 </td>
  //                 <td className="align-middle">
  //                   <img
  //                     src={`${process.env.REACT_APP_IMAGE_Link}${el.image}`}
  //                     alt=""
  //                     className="gift-img"
  //                   />
  //                 </td>

  //                 <td className="align-middle">
  //                   <span className="text-control">{el.description}</span>
  //                 </td>
  //                 {/* <td className="align-middle">
  //                   <div className="d-flex align-items-center">
  //                     <div className="lh-1">
  //                       <h5 className=" mb-1">{el.gift}</h5>
  //                     </div>
  //                   </div>
  //                 </td> */}
  //                 <td className="align-middle ">
  //                   <div className="d-flex align-items-center">
  //                     <Link
  //                       to={`/admin/edit-gifts/${el.id}`}
  //                       className="btn btn-success btn-sm btn-icon-text me-3"
  //                     >
  //                       Edit <i className="bi bi-pencil-square"></i>
  //                     </Link>
  //                     <button
  //                       type="button"
  //                       className="btn btn-danger btn-sm btn-icon-text"
  //                       onClick={() => {
  //                         delOrder(el.id);
  //                       }}
  //                     >
  //                       Delete <i className="bi bi-x-circle"></i>
  //                     </button>
  //                   </div>
  //                 </td>
  //               </tr>
  //             ))
  //           ) : (
  //             <tr>
  //               <td colSpan="6" className="text-center text-capitalize">
  //                 No record found
  //               </td>
  //             </tr>
  //           )}
  //         </tbody>
  //       </table>
  //       {totalResults > limit && totalPages > 1 ? (
  //         <Pagination
  //           activePage={currentPage}
  //           itemsCountPerPage={parseInt(limit)}
  //           totalItemsCount={totalResults}
  //           onChange={(e) => {
  //             orderList(e, limit);
  //           }}
  //           pageRangeDisplayed={8}
  //           itemClass="page-item"
  //           linkClass="page-link"
  //           firstPageText="First Page"
  //           lastPageText="Last Page"
  //         />
  //       ) : (
  //         ""
  //       )}
  //     </div>
  //   );
  // };

  const deletAllOrders = async () => {
    Swal.fire({
      title: "Are You Sure?",
      text: "Do You Want To Delete All Orders",
      icon: "question",
      iconColor: "#e04f5d",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      confirmButtonText: "Yes",
      cancelButtonText: "Close",
      customClass: {
        container: "del-popup", // Apply your custom class to the container
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await AdminListService.deletAllOrders(admin_id);

          orderList(currentPage, limit, values, currentYear);

          Swal.fire({
            icon: "success",
            title: "Deleted Successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
        } catch (err) {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            iconColor: "#e04f5d",
            confirmButtonColor: "#e72e2e",
            text: "Something went wrong!",
          });
        }
      }
    });
  };
  const archiveAllOrders = async () => {
    Swal.fire({
      title: "Are You Sure?",
      text: "Do You Want To Archive All Orders",
      icon: "question",
      iconColor: "#e04f5d",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      confirmButtonText: "Yes",
      cancelButtonText: "Close",
      customClass: {
        container: "del-popup", // Apply your custom class to the container
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await AdminListService.archiveAllOrders(admin_id);

          orderList(currentPage, limit, values, currentYear);

          Swal.fire({
            icon: "success",
            title: "Archived Successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
        } catch (err) {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            iconColor: "#e04f5d",
            confirmButtonColor: "#e72e2e",
            text: "Something went wrong!",
          });
        }
      }
    });
  };

  const archiveOrder = async (order_id) => {
    settableLoader(true);
    try {
      const response = await AdminListService.orderArchive(admin_id, order_id);

      toast.success(response?.data?.response, {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      settableLoader(false);
      setTimeout(() => {
        orderList(currentPage, limit, values, currentYear);
      }, 1000);
    } catch (err) {
      console.log(err);
      settableLoader(false);

      if (
        err?.response?.data?.error &&
        typeof err?.response?.data?.error === "string"
      ) {
        toast.error(err?.response?.data?.error, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error("Something went wrong, try again", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,

      initialValues: {
        fieldtype: "",
        searchval: "",
      },
      validationSchema: quizSearch,
      onSubmit: (values) => {
        orderList("1", limit, values, currentYear);
      },
    });

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <Sidebar />
          <MainHeading heading="Manage Orders" />

          <div className="mt-4 row">
            <div className="col-md-12 col-12">
              <div className="card dashboard-cards">
                <div className="bg-white  py-3 card-header">
                  <div className="row">
                    <div className="col-md-4">
                      <h4 className="mb-0">
                        Total Orders:{" "}
                        <span className="text-primary-color fw-bold">
                          {formatThousands(totalResults, {
                            separator: ",",
                          })}
                        </span>
                      </h4>
                    </div>
                    <div className="col-md-8 text-end">
                      <button
                        onClick={archiveAllOrders}
                        type="button"
                        className="btn btn-success btn-sm btn-icon-text me-2"
                      >
                        Archive All Orders <i className="bi bi bi-archive"></i>
                      </button>
                      <button
                        onClick={deletAllOrders}
                        type="button"
                        className="btn btn-danger btn-sm btn-icon-text"
                      >
                        Delete All Orders <i className="bi bi-x-circle"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <form className="row" onSubmit={handleSubmit} noValidate>
                    <div className="col-6 ">
                      <Link
                        to="/admin/archOrder"
                        className="btn btn-primary btn-sm btn-icon-text me-2"
                      >
                        Show Archive Orders <i className="bi bi bi-archive"></i>
                      </Link>
                    </div>
                    <div className="col-6 text-end">
                      <a
                        href={`${process.env.REACT_APP_API_Link}export-orders/${currentYear}/active?time=${currentDateTime}`}
                        className="btn btn-primary btn-sm btn-icon-text"
                      >
                        To Export As Excel Click here{" "}
                        <i className="bi bi-filetype-exe"></i>
                      </a>
                    </div>
                    <div className="col-12">
                      <hr />
                    </div>
                    <div className="col-lg-3">
                      <div className="input-field-box mb-lg-0">
                        <label>Show Column</label>
                        <select
                          className={`form-select ${
                            errors.fieldtype && touched.fieldtype
                              ? "is-danger"
                              : ""
                          }`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="fieldtype"
                          value={values.fieldtype || ""}
                          required
                        >
                          <option value="">Select Column</option>

                          <option value="fname">First Name</option>
                          <option value="lname">Last Name</option>
                          <option value="email">Email</option>
                          <option value="city">City</option>
                          <option value="state">State</option>
                          <option value="address1">Address1</option>
                          <option value="address2">Address2</option>
                          <option value="phone">Phone</option>
                          <option value="zip">Zip</option>
                          <option value="size">Size</option>
                          <option value="product">Color</option>
                          <option value="voucher">Voucher</option>
                          <option value="date">Date</option>
                        </select>
                      </div>
                      {errors.fieldtype && touched.fieldtype ? (
                        <span className="text-danger">{errors.fieldtype}</span>
                      ) : null}
                    </div>
                    <div className="col-lg-5">
                      <div className="input-field-box mb-lg-0">
                        <label>Search</label>
                        <div className="input-group">
                          <input
                            type={values.fieldtype === "date" ? "date" : "text"}
                            className={`form-control ${
                              errors.searchval && touched.searchval
                                ? "is-danger"
                                : ""
                            }`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="searchval"
                            value={values.searchval || ""}
                            required
                          />

                          <button className="btn btn-primary " type="submit">
                            Search
                          </button>
                          <button
                            className="btn btn-primary"
                            type="reset"
                            onClick={() => window.location.reload(false)}
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                      {errors.searchval && touched.searchval ? (
                        <span className="text-danger">{errors.searchval}</span>
                      ) : null}
                    </div>
                    <div className="col-lg-2">
                      <div className="input-field-box mb-0">
                        <label>Show Entries By Year</label>
                        <select
                          className="form-select"
                          value={currentYear}
                          onChange={(e) => {
                            setCurrentYear(e.target.value);
                            orderList("1", limit, values, e.target.value);
                          }}
                        >
                          {years.length
                            ? years.map((el, index) => (
                                <option value={el.year} key={index}>
                                  {el.year}
                                </option>
                              ))
                            : null}
                          {/* <option value="2021" key="2021">
                            2021
                          </option>
                          <option value="2022" key="2022">
                            2022
                          </option> */}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <div className="input-field-box mb-0">
                        <label>Show Entries</label>
                        <select
                          className="form-select"
                          value={limit}
                          onChange={(e) =>
                            orderList("1", e.target.value, values, currentYear)
                          }
                        >
                          <option value="10">10</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="250">250</option>
                          <option value="500">500</option>
                        </select>
                      </div>
                    </div>
                  </form>
                </div>
                {tableLoader ? (
                  <div className="card-body pt-0">
                    <Skeleton count={11} height="50px" />
                  </div>
                ) : (
                  <div className="table-responsive">
                    <table className="text-nowrap  table">
                      <thead className="table-light">
                        <tr>
                          <th scope="col">Sr#</th>

                          <th scope="col">Profile Detail</th>
                          <th scope="col">Order Detail</th>
                          <th scope="col">Order Date</th>

                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData.length ? (
                          tableData.map((el, index) => (
                            <tr key={index}>
                              <td className="align-middle">
                                {currentPage === 1
                                  ? index + 1
                                  : (currentPage - 1) * limit + index + 1}
                              </td>

                              <td className="align-middle">
                                <table className="inner-table">
                                  <tbody>
                                    <tr>
                                      <td>
                                        <strong>Name:</strong>
                                      </td>
                                      <td>
                                        {" "}
                                        <span className="text-control">
                                          {el.name ? el.name : "N/A"}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <strong>Email:</strong>
                                      </td>
                                      <td>
                                        <span className="text-control">
                                          {el.email ? el.email : "N/A"}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <strong>City/State:</strong>
                                      </td>
                                      <td>
                                        <span className="text-control">
                                          {el.city}/{el.state}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <strong>Address1:</strong>
                                      </td>
                                      <td>
                                        <span className="text-control">
                                          {el.address1 ? el.address1 : "N/A"}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <strong>Address2:</strong>
                                      </td>
                                      <td>
                                        <span className="text-control">
                                          {el.address2 ? el.address2 : "N/A"}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <strong>Phone:</strong>
                                      </td>
                                      <td>
                                        <span className="text-control">
                                          {el.phone ? el.phone : "N/A"}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <strong>Zip Code:</strong>
                                      </td>
                                      <td>
                                        {" "}
                                        <span className="text-control">
                                          {el.zip ? el.zip : "N/A"}
                                        </span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td className="align-middle">
                                <table className="inner-table">
                                  <tbody>
                                    <tr>
                                      <td>
                                        <strong>Size:</strong>
                                      </td>
                                      <td>
                                        <span className="text-control">
                                          {el.size ? el.size : "N/A"}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <strong>Color:</strong>
                                      </td>
                                      <td>
                                        <span className="text-control">
                                          {el.gift ? el.gift : "N/A"}

                                          {/* <i class="icon ion-ios-checkmark"></i> */}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <strong>Voucher:</strong>
                                      </td>
                                      <td>
                                        <span className="text-control">
                                          {el.vocher ? el.vocher : "N/A"}
                                        </span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>

                              <td className="align-middle">
                                {/* {moment(el.regDate).format("MM-DD-YYYY")} */}
                                {el.regDate !== "" &&
                                  el.regDate !== "0000-00-00" &&
                                  moment(el.regDate).format("MM-DD-YYYY")}
                              </td>

                              <td className="align-middle ">
                                <div className="d-flex align-items-center">
                                  <button
                                    onClick={() => {
                                      archiveOrder(el.id);
                                    }}
                                    className="btn btn-success btn-sm btn-icon-text me-3"
                                  >
                                    Archive <i className="bi bi bi-archive"></i>
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-danger btn-sm btn-icon-text"
                                    onClick={() => {
                                      delOrder(el.id);
                                    }}
                                  >
                                    Delete <i className="bi bi-x-circle"></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan="6"
                              className="text-center text-capitalize"
                            >
                              No record found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    {totalResults > limit && totalPages > 1 ? (
                      <Pagination
                        activePage={currentPage}
                        itemsCountPerPage={parseInt(limit)}
                        totalItemsCount={totalResults}
                        onChange={(e) => {
                          orderList(e, limit, values, currentYear);
                        }}
                        pageRangeDisplayed={8}
                        itemClass="page-item"
                        linkClass="page-link"
                        firstPageText="First Page"
                        lastPageText="Last Page"
                      />
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
