import React from "react";
import { useState } from "react";

function SurvayPage() {
  const [checkbox1, setCheckbox1] = useState("");
  const [checkbox2, setCheckbox2] = useState("");
  const [checkbox3, setCheckbox3] = useState("");
  const [checkbox4, setCheckbox4] = useState("");
  const [checkbox5, setCheckbox5] = useState("");
  const [checkbox6, setCheckbox6] = useState("");
  const [checkbox7, setCheckbox7] = useState("");
  const [checkbox8, setCheckbox8] = useState("");
  const [checkbox9, setCheckbox9] = useState("");
  const [checkbox10, setCheckbox10] = useState("");

  const handleChangeone = (e) => {
    // const { name, value } = e.target;

    const ans1 = e.target.value;

    localStorage.setItem("ans1", ans1);
  };

  const handleChangtwo = (e, value) => {
    var checking = "" + e.target.id;
    // const checkbox1= !checkbox1;

    switch (checking) {
      case "q1":
        var q1value = "";
        if (!checkbox1) {
          q1value = e.target.value;
          localStorage.setItem("First", q1value);
        } else {
          q1value = "";
          localStorage.removeItem("First");
        }
        setCheckbox1(q1value);

        break;
      case "q2":
        var q1value = "";

        if (!checkbox2) {
          q1value = e.target.value;
          localStorage.setItem("Second", q1value);
        } else {
          q1value = "";
          localStorage.removeItem("Second");
        }
        setCheckbox2(q1value);
        break;

      case "q3":
        var q1value = "";
        if (!checkbox3) {
          q1value = e.target.value;
          localStorage.setItem("Third", q1value);
        } else {
          q1value = "";
          localStorage.removeItem("Third");
        }
        setCheckbox3(q1value);

        break;

      case "q4":
        var q1value = "";
        if (!checkbox4) {
          q1value = e.target.value;
          localStorage.setItem("Four", q1value);
        } else {
          q1value = "";
          localStorage.removeItem("Four");
        }
        setCheckbox4(q1value);

        break;

      case "q5":
        var q1value = "";
        if (!checkbox5) {
          q1value = e.target.value;
          localStorage.setItem("Five", q1value);
        } else {
          q1value = "";
          localStorage.removeItem("Five");
        }
        setCheckbox5(q1value);

        break;

      case "q6":
        var q1value = "";
        if (!checkbox6) {
          q1value = e.target.value;
          localStorage.setItem("Six", q1value);
        } else {
          q1value = "";
          localStorage.removeItem("Six");
        }
        setCheckbox6(q1value);

        break;

      case "q7":
        var q1value = "";
        if (!checkbox7) {
          q1value = e.target.value;
          localStorage.setItem("Seven", q1value);
        } else {
          q1value = "";
          localStorage.removeItem("Seven");
        }
        setCheckbox7(q1value);

        break;

      case "q8":
        var q1value = "";
        if (!checkbox8) {
          q1value = e.target.value;
          localStorage.setItem("Eight", q1value);
        } else {
          q1value = "";
          localStorage.removeItem("Eight");
        }
        setCheckbox8(q1value);

        break;

      case "q9":
        var q1value = "";
        if (!checkbox9) {
          q1value = e.target.value;
          localStorage.setItem("Nine", q1value);
        } else {
          q1value = "";
          localStorage.removeItem("Nine");
        }
        setCheckbox9(q1value);

        break;
      case "q10":
        var q1value = "";
        if (!checkbox10) {
          q1value = e.target.value;
          localStorage.setItem("Ten", q1value);
        } else {
          q1value = "";
          localStorage.removeItem("Ten");
        }
        setCheckbox10(q1value);

        break;
    }
  };
  const handleChangthree = (e) => {
    const ans3 = e.target.value;
    localStorage.setItem("ans3", ans3);
  };
  const handleChangefour = (e) => {
    const ans4 = e.target.value;
    localStorage.setItem("ans4", ans4);
  };
  const handleChangefive = (e) => {
    const ans5 = e.target.value;
    localStorage.setItem("ans5", ans5);
  };
  const handleChangesix = (e) => {
    const ans6 = e.target.value;
    localStorage.setItem("ans6", ans6);
  };
  const handleChangseven = (e) => {
    const ans7 = e.target.value;
    localStorage.setItem("ans7", ans7);
  };
  const handleChangeight = (e) => {
    const ans8 = e.target.value;
    localStorage.setItem("ans8", ans8);
  };
  const handleChangnine = (e) => {
    const ans9 = e.target.value;
    localStorage.setItem("ans9", ans9);
  };
  const handleChangten = (e) => {
    const ans10 = e.target.value;
    localStorage.setItem("ans10", ans10);
  };
  return (
    <div className="cards">
      <h1 className="h1-deading"> Online Customer Survey</h1>
      <hr className="hr-margin" />
      <input type="hidden" name="survey" value="yes" />
      <div className="fl wid100">
        <h3 className="form-h3">
          1) Overall, how satisfied or dissatisfied are you with our company?
        </h3>
      </div>{" "}
      <label>
        {" "}
        <input
          type="radio"
          name="q1"
          value="Very satisfied"
          onChange={handleChangeone}
        />
        Very satisfied
      </label>
      <label>
        {" "}
        <input
          type="radio"
          name="q1"
          value="Somewhat satisfied"
          onChange={handleChangeone}
        />
        Somewhat satisfied
      </label>{" "}
      <label>
        {" "}
        <input
          type="radio"
          name="q1"
          value="Neither satisfied nor dissatisfied"
          onChange={handleChangeone}
        />
        Neither satisfied nor dissatisfied{" "}
      </label>{" "}
      <label>
        {" "}
        <input
          type="radio"
          name="q1"
          value="Somewhat dissatisfied"
          onChange={handleChangeone}
        />
        Somewhat dissatisfied
      </label>{" "}
      <label>
        {" "}
        <input
          type="radio"
          name="q1"
          value="Very dissatisfied"
          onChange={handleChangeone}
        />
        Very dissatisfied{" "}
      </label>
      <br />
      <span id="q1_m"></span>
      <h3 className="form-h3">
        2) Which of the following words would you use to describe our products?
        Select all that apply.
      </h3>
      <div className="fl wid100"></div>{" "}
      <label>
        {" "}
        <input
          type="checkbox"
          name="q2"
          value="Reliable"
          id="q1"
          onChange={handleChangtwo}
        />
        Reliable{" "}
      </label>{" "}
      <label>
        {" "}
        <input
          type="checkbox"
          name="q2"
          value="High quality"
          id="q2"
          onChange={handleChangtwo}
        />
        High quality{" "}
      </label>{" "}
      <label>
        {" "}
        <input
          type="checkbox"
          name="q2"
          value="Useful"
          id="q3"
          onChange={handleChangtwo}
        />
        Useful{" "}
      </label>{" "}
      <label>
        {" "}
        <input
          type="checkbox"
          name="q2"
          value="Unique"
          id="q4"
          onChange={handleChangtwo}
        />
        Unique{" "}
      </label>{" "}
      <label>
        {" "}
        <input
          type="checkbox"
          name="q2"
          id="q5"
          value="Good value for money"
          onChange={handleChangtwo}
        />
        Good value for money{" "}
      </label>{" "}
      <label>
        {" "}
        <input
          type="checkbox"
          name="q2"
          id="q6"
          value="Overpriced"
          onChange={handleChangtwo}
        />
        Overpriced{" "}
      </label>{" "}
      <label>
        {" "}
        <input
          type="checkbox"
          name="q2"
          id="q7"
          value="Impractical"
          onChange={handleChangtwo}
        />
        Impractical{" "}
      </label>{" "}
      <label>
        {" "}
        <input
          type="checkbox"
          name="q2"
          id="q8"
          value="Ineffective"
          onChange={handleChangtwo}
        />
        Ineffective{" "}
      </label>{" "}
      <label>
        {" "}
        <input
          type="checkbox"
          name="q2"
          id="q9"
          value="Poor quality"
          onChange={handleChangtwo}
        />
        Poor quality{" "}
      </label>{" "}
      <label>
        {" "}
        <input
          type="checkbox"
          name="q2"
          id="q10"
          value="Unreliable"
          onChange={handleChangtwo}
        />
        Unreliable{" "}
      </label>
      <br />
      <span id="q2_m"></span>
      <h3 className="form-h3">3) How well do our products meet your needs?</h3>
      <div className="fl wid100"></div>{" "}
      <label>
        {" "}
        <input
          type="radio"
          name="q3"
          value="Extremely well"
          onChange={handleChangthree}
        />
        Extremely well
      </label>{" "}
      <label>
        {" "}
        <input
          type="radio"
          name="q3"
          value="Very well"
          onChange={handleChangthree}
        />
        Very well
      </label>
      <label>
        {" "}
        <input
          type="radio"
          name="q3"
          value="Somewhat well"
          onChange={handleChangthree}
        />
        Somewhat well
      </label>{" "}
      <label>
        {" "}
        <input
          type="radio"
          name="q3"
          value="Not so well"
          onChange={handleChangthree}
        />
        Not so well
      </label>{" "}
      <label>
        {" "}
        <input
          type="radio"
          name="q3"
          value="Not at all well"
          onChange={handleChangthree}
        />
        Not at all well{" "}
      </label>
      <br /> <span id="q3_m"></span>
      <h3 className="form-h3">
        4) How would you rate the quality of our products?
      </h3>
      <div className="fl wid100"></div>{" "}
      <label>
        {" "}
        <input
          type="radio"
          name="q4"
          value="Very high quality"
          onChange={handleChangefour}
        />
        Very high quality
      </label>{" "}
      <label>
        {" "}
        <input
          type="radio"
          name="q4"
          value="High quality"
          onChange={handleChangefour}
        />
        High quality
      </label>
      <label>
        {" "}
        <input
          type="radio"
          name="q4"
          value="Neither high nor low quality"
          onChange={handleChangefour}
        />
        Neither high nor low quality
      </label>{" "}
      <label>
        {" "}
        <input
          type="radio"
          name="q4"
          value="Low quality"
          onChange={handleChangefour}
        />
        Low quality
      </label>{" "}
      <label>
        {" "}
        <input
          type="radio"
          name="q4"
          value="Very low quality"
          onChange={handleChangefour}
        />
        Very low quality{" "}
      </label>
      <br /> <span id="q4_m"></span>
      <h3 className="form-h3">
        5) How would you rate the value for money of our products?
      </h3>
      <div className="fl wid100"></div>{" "}
      <label>
        {" "}
        <input
          type="radio"
          name="q5"
          value="Excellent"
          onChange={handleChangefive}
        />
        Excellent
      </label>{" "}
      <label>
        {" "}
        <input
          type="radio"
          name="q5"
          value="Above average"
          onChange={handleChangefive}
        />
        Above average
      </label>{" "}
      <label>
        {" "}
        <input
          type="radio"
          name="q5"
          value="Average"
          onChange={handleChangefive}
        />
        Average
      </label>
      <br />{" "}
      <label>
        {" "}
        <input
          type="radio"
          name="q5"
          value="Below average"
          onChange={handleChangefive}
        />
        Below average
      </label>{" "}
      <label>
        {" "}
        <input
          type="radio"
          name="q5"
          value="Poor"
          onChange={handleChangefive}
        />
        Poor{" "}
      </label>
      <br />
      <span id="q5_m"></span>
      <h3 className="form-h3">
        6) How responsive have we been to your questions or concerns about our
        products?
      </h3>
      <div className="fl wid100"></div>{" "}
      <label>
        {" "}
        <input
          type="radio"
          name="q6"
          value="Extremely responsive"
          onChange={handleChangesix}
        />
        Extremely responsive
      </label>{" "}
      <label>
        {" "}
        <input
          type="radio"
          name="q6"
          value="Very responsive"
          onChange={handleChangesix}
        />
        Very responsive
      </label>{" "}
      <label>
        {" "}
        <input
          type="radio"
          name="q6"
          value="Moderately responsive"
          onChange={handleChangesix}
        />
        Moderately responsive
      </label>{" "}
      <label>
        {" "}
        <input
          type="radio"
          name="q6"
          value="Not so responsive"
          onChange={handleChangesix}
        />
        Not so responsive
      </label>{" "}
      <label>
        {" "}
        <input
          type="radio"
          name="q6"
          value="Not at all responsive"
          onChange={handleChangesix}
        />
        Not at all responsive{" "}
      </label>{" "}
      <label>
        {" "}
        <input
          type="radio"
          name="q6"
          value="Not applicable"
          onChange={handleChangesix}
        />
        Not applicable{" "}
      </label>
      <br /> <span id="q6_m"></span>
      <h3 className="form-h3">
        7) How long have you been a customer of our company?
      </h3>
      <div className="fl wid100"></div>{" "}
      <label>
        {" "}
        <input
          type="radio"
          name="q7"
          value="This is my first purchase"
          onChange={handleChangseven}
        />
        This is my first purchase
      </label>{" "}
      <label>
        {" "}
        <input
          type="radio"
          name="q7"
          value="Less than six months"
          onChange={handleChangseven}
        />
        Less than six months
      </label>{" "}
      <label>
        {" "}
        <input
          type="radio"
          name="q7"
          value="Six months to a year"
          onChange={handleChangseven}
        />
        Six months to a year
      </label>{" "}
      <label>
        {" "}
        <input
          type="radio"
          name="q7"
          value="1 - 2 years"
          onChange={handleChangseven}
        />
        1 - 2 years
      </label>{" "}
      <label>
        {" "}
        <input
          type="radio"
          name="q7"
          value="3 or more years"
          onChange={handleChangseven}
        />
        3 or more years{" "}
      </label>{" "}
      <label>
        {" "}
        <input
          type="radio"
          name="q7"
          value="I haven't made a purchase yet"
          onChange={handleChangseven}
        />
        I haven't made a purchase yet{" "}
      </label>
      <br /> <span id="q7_m"></span>
      <h3 className="form-h3">
        8) How likely are you to purchase any of our products again?
      </h3>
      <div className="fl wid100"></div>{" "}
      <label>
        {" "}
        <input
          type="radio"
          name="q8"
          value="Extremely likely"
          onChange={handleChangeight}
        />
        Extremely likely
      </label>{" "}
      <label>
        {" "}
        <input
          type="radio"
          name="q8"
          value="Very likely"
          onChange={handleChangeight}
        />
        Very likely
      </label>
      <br />{" "}
      <label>
        {" "}
        <input
          type="radio"
          name="q8"
          value="Somewhat likely"
          onChange={handleChangeight}
        />
        Somewhat likely
      </label>{" "}
      <label>
        {" "}
        <input
          type="radio"
          name="q8"
          value="Not so likely"
          onChange={handleChangeight}
        />
        Not so likely
      </label>{" "}
      <label>
        {" "}
        <input
          type="radio"
          name="q8"
          value="Not at all likely"
          onChange={handleChangeight}
        />
        Not at all likely{" "}
      </label>
      <br />
      <span id="q8_m"></span>
      <div className="fl wid100"></div>
      <h3 className="form-h3">
        9) How likely is it that you would recommend this company to a friend or
        colleague?
      </h3>
      <div className="likely-rate">
        <span className="text-start">Not at all likely</span>
        <span className="text-end">Extremely likely</span> <br />
        <label>
          {" "}
          <input type="radio" name="q9" value="1" onChange={handleChangnine} />1
        </label>{" "}
        <label>
          {" "}
          <input
            type="radio"
            name="q9"
            value="2"
            onChange={handleChangnine}
          />{" "}
          2
        </label>{" "}
        <label>
          {" "}
          <input type="radio" name="q9" value="3" onChange={handleChangnine} />3
        </label>{" "}
        <label>
          {" "}
          <input type="radio" name="q9" value="4" onChange={handleChangnine} />4
        </label>{" "}
        <label>
          {" "}
          <input
            type="radio"
            name="q9"
            value="5"
            onChange={handleChangnine}
          />5{" "}
        </label>{" "}
        <label>
          {" "}
          <input
            type="radio"
            name="q9"
            value="6"
            onChange={handleChangnine}
          />6{" "}
        </label>{" "}
        <label>
          {" "}
          <input
            type="radio"
            name="q9"
            value="7"
            onChange={handleChangnine}
          />7{" "}
        </label>{" "}
        <label>
          {" "}
          <input
            type="radio"
            name="q9"
            value="8"
            onChange={handleChangnine}
          />8{" "}
        </label>{" "}
        <label>
          {" "}
          <input
            type="radio"
            name="q9"
            value="9"
            onChange={handleChangnine}
          />9{" "}
        </label>{" "}
        <label>
          {" "}
          <input type="radio" name="q9" value="10" onChange={handleChangnine} />
          10{" "}
        </label>
        <div className="clearfix"></div>
      </div>
      <span id="q9_m"></span>{" "}
      <h3 className="form-h3">
        10) Do you have any other comments, questions, or concerns?
      </h3>
      <div className="fl wid100">
        <textarea
          name="q10"
          className="textarea"
          spellCheck="true"
          rows="10"
          cols="90"
          onChange={handleChangten}
        ></textarea>
      </div>
      <span id="q10_m"></span>
    </div>
  );
}
export default SurvayPage;
